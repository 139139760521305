import React from "react";
import { Link } from "gatsby";
import SEO from "../components/SEO";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import BannerDesign from "../components/BannerDesign";

const LandingDesign = (props) => {
  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://brandinavian.com/#organization",
        name: "Brandinavian",
        url: "https://brandinavian.com/",
        sameAs: [
          "https://www.facebook.com/brandinavian/",
          "https://www.instagram.com/brandinavian/",
          "https://www.linkedin.com/company/brandinavian/",
          "https://twitter.com/brandinavian",
        ],
        logo: {
          "@type": "ImageObject",
          "@id": "https://brandinavian.com/#logo",
          inLanguage: "en-US",
          url:
            "https://cdn.brandinavian.com/wp-content/uploads/2020/09/brandinavian-logo.png",
          width: 1112,
          height: 116,
          caption: "Brandinavian | Modern Web Development",
        },
        image: { "@id": "https://brandinavian.com/#logo" },
      },
      {
        "@type": "WebSite",
        "@id": "https://brandinavian.com/#website",
        url: "https://brandinavian.com/",
        name: "Brandinavian",
        description: "USA Web Developer",
        publisher: { "@id": "https://brandinavian.com/#organization" },
        inLanguage: "en-US",
      },
      {
        "@type": "WebPage",
        "@id": "https://brandinavian.com/design/#webpage",
        url: "https://brandinavian.com/design/",
        name: "Design | Brandinavian",
        isPartOf: { "@id": "https://brandinavian.com/#website" },
        description:
          "Achieve a timeless look, that will solidify your brand. Get started with Brandinavian today!",
        breadcrumb: {
          "@id": "https://brandinavian.com/design/#breadcrumb",
        },
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://brandinavian.com/design"],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": "https://brandinavian.com/design/#breadcrumb",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@type": "WebPage",
              "@id": "https://brandinavian.com/",
              url: "https://brandinavian.com/",
              name: "Web developer",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@type": "WebPage",
              "@id": "https://brandinavian.com/design",
              url: "https://brandinavian.com/design",
              name: "Modern web development",
            },
          },
        ],
      },
    ],
  };

  return (
    <Layout altLogo={'black'}>
      <SEO
        title="Design | Brandinavian"
        lang="en-US"
        schemaMarkup={schema}
        description="Achieve a timeless look, that will solidify your brand. Get started with Brandinavian today!"
      />
      <BannerDesign />
      <div id="main" className="alt darkmode">
        <section className="pre-article dark">
          <li className="tag">Branding, Identities & Graphics</li>
        </section>
        <section id="one" className="article-section main-service">
          <div className="inner article service-article">
            <header className="major">
              <h2>First impressions</h2>
            </header>
            <p>
              Good ones are hard to make, and it would appear that they matter.
              <b> A lot.</b><br/> 
              <a
                href="https://www.tandfonline.com/doi/abs/10.1080/01449290500330448"
                target="_blank"
                rel="noopener"
                rel="noreferrer"
              >Three studies</a> were conducted by researchers in Canada, to ascertain how quickly
              people form an opinion about web page visual appeal. The answer?
              50 milliseconds. <br />
              <br />
              That is <b>five percent of a second</b>. Quite literally the blink
              of an eye.
              <br />
              <br /> Another group of researches from Sweden then posed the next
              obvious question:<i> But how long do these impressions last? </i>
              <a
                href="https://www.researchgate.net/publication/339773074_The_Persistence_of_First_Impressions_The_Effect_of_Repeated_Interactions_on_the_Perception_of_a_Social_Robot"
                target="_blank"
                rel="noopener"
                rel="noreferrer"
              > The data indicates
              </a>, that they are very hard to change. Especially early visual
              impressions. These tend to stick, and therefore repeat
              interactions do not improve the overall impression of your brand
              or website, if these are not aesthetically pleasing from the
              get-go.
            </p>
            <div className="dark-mode-image green">
              <Img fluid={props.data.imageOne.childImageSharp.fluid} alt="" />
              <li className="tag branded">BRANDINAVIAN</li>
            </div>
            <header className="major">
              <h2>My process</h2>
            </header>
            <p>
              When I approach any design challenge, or any kind of graphical
              work, it starts with research. It is important to establish what
              the goal of your communication is. <b>It is easy for me to make assumptions</b> about, what I think you are trying to convey or what I think that
              your transmission is about - but this is not the way that you will
              end up being successful in your efforts, and typically not the way
              that we manage to convey the right aesthetic for you either. <b>It
              has to start with you</b>, and your values. So let's talk a
              bit before I create anything - because I have learned through the
              years, that it very often doesen't pay to produce anything if
              this guidepost is not established.
            </p>
            <div className="dark-mode-image blue">
              <Img fluid={props.data.imageThree.childImageSharp.fluid} alt="" />
              <li className="tag branded">BRANDINAVIAN</li>
            </div>
            <header className="major">
              <h2>Getting down to work</h2>
            </header>
            <p>
              Practically, I will get to work on creating designs or graphical
              work for you after establishing this base. I have about 10 years
              of experience in professional production of materials using Adobe
              software - Photoshop, Illustrator, XD, InDesign and Premiere are
              all dear friends to me. When I reach a stage, where I can
              present a conceptual overview to you -
              depending on the extensitivity of the work - we will then
              enter a phase of having ongoing dialogue about the direction
              of the project. We will set a deadline that we both agree on, and
              I will finish the work. If you require Figma
              or Sketch files instead of XD, that is completely fine with me, I
              have experience with both of these as well.
            </p>
            <div className="dark-mode-image yellow">
              <Img fluid={props.data.imageTwo.childImageSharp.fluid} alt="" />
              <li className="tag branded">BRANDINAVIAN</li>
            </div>
            <header className="major">
              <h2>If you can dream it</h2>
            </header>
            <p>
              I can realize it. Chances are I already have
              some experience in the arena of design that your project
              requires practical knowledge within. I have worked with physical print
              materials, I have designed canvas prints for galleries, I have designed numerous
              digital identities - and almost everything in-between these. GIFs?
              Concept-art? Infographics? ... Origami? If you can
              name it, I can probably help you.
            </p>
            <ul className="actions align-center">
              <li>
                <Link to="/contact" className="button">
                  Start a project today!
                </Link>
              </li>
            </ul>
          </div>
          <div className="inner article about-author">
            <div className="author-image-box">
              <Img
                className="large-author-image"
                fluid={props.data.imageFour.childImageSharp.fluid}
              />
            </div>
            <div className="author-information">
              <header className="major special">
                <h2 className="large-author-name">Hi, I'm Mads</h2>
              </header>
              <p className="author-biography">
                I'm a Full Stack Engineer and Media Science graduate. Through
                Brandinavian, I help awesome people all over the world,
                designing and engineering digital solutions. I build
                best-in-class, scalable web applications, that convert and
                communicate well.
              </p>
              <p className="author-links" />
              <li className="tag">
                <Link to="/contact">Get in touch</Link>
              </li>
              <li className="tag">
                <Link to="/contact">Request Resume</Link>
              </li>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default LandingDesign;

export const designQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "statue.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "astro-design.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "warhol.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageFour: file(relativePath: { eq: "creative-author.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
