import React from "react";

const Contact = () => (
  <section id="contact">
    <div className="inner">
      <section>
        <form name="footer-form" method="POST" data-netlify="true" netlify-honeypot="bot-field" action="/confirmation">
          <div className="field half first">
            <label htmlFor="name">Name</label>
            <input name="name" type="text" id="name" required />
          </div>
          <div className="field half">
            <label htmlFor="email">Email</label>
            <input name="email" type="text" id="email" required />
          </div>
          <div className="field">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" rows="6"></textarea>
            <input name="bot-field" type="hidden" />
            <input name="form-name" type="hidden" value="footer-form" />
          </div>
          <ul className="actions">
            <li>
              <input name="submit" type="submit" value="Send Message" className="special"/>
            </li>
            <li>
              <input type="reset" value="Clear" />
            </li>
          </ul>
        </form>
      </section>
      <section className="split">
        <section>
          <div className="contact-method">
            <span className="icon alt fa-envelope"></span>
            <h3>Email</h3>
            <a href="mailto:hello@brandinavian.com">hello@brandinavian.com</a>
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon alt fa-skype"></span>
            <h3>Skype</h3>
            <span>Brandinavian</span>
          </div>
        </section>
        <section>
          <div className="contact-method">
            <span className="icon alt fa-location-arrow"></span>
            <h3>Location</h3>
            <span>
              94704, Berkeley
              <br />
              California, USA
            </span>
          </div>
        </section>
      </section>
    </div>
  </section>
);


export default Contact;
